@import './Styles';
@tailwind base;
@tailwind components;
@tailwind utilities;


[data-testid="calendar-start"] {
	background-color: #fffbe0 !important;
	.rs-calendar-header-title {
		&.rs-calendar-header-title-date {
			&.rs-btn {
				&.rs-btn-subtle {
					&.rs-btn-xs {
						font-size: 1.25rem !important;
						color: blue !important;
					}
				}
			}
		}
	}
}
// rs-calendar-table-cell rs-calendar-table-cell-in-range
.rs-calendar-table-cell {
	&.rs-calendar-table-cell-in-range {
		background-color: #ffef3fd1 !important;
	}
}

[data-testid="calendar-end"] {
	background-color: #ffe9fc !important;
	.rs-calendar-header-title {
		&.rs-calendar-header-title-date {
			&.rs-btn {
				&.rs-btn-subtle {
					&.rs-btn-xs {
						font-size: 1.25rem !important;
						color: red !important;
					}
				}
			}
		}
	}
}

.App-root {
	@include flex(row, flex-start, flex-start);
	min-height: 100%;
	height: auto;
	position: relative;

	.internal-data{
		position: absolute;
		top: 0;
		background: #94d2bd;
		z-index: 999;
		left: 50%;
		padding: 4px 8px;
		border-radius: 0 0 8px 8px;
		transition-duration: 1s;
	}
	
	.App-content {
		width: 100%;
		height: auto;
		min-width: 768px;
		min-height: 100vh;
		padding-left: 0px;

		@include xl {
			padding-left: 16rem;
		}
	}

	.page {
		height: auto;
		min-height: calc(100vh - 80px);

		.heading {
			font-size: 1.5rem;
		}

		.sub-heading {
			font-size: 1.25rem;
			margin: 1.25rem 0px;
		}
	}

	.list-page-root {
		// height: calc(100vh - 130px);
		// overflow: auto;
	}

	.row {
		@include flex(row, flex-start, flex-start);
		width: 100%;
	}

	.form-fieldset {
		min-width: 0px;
		width: 100%;
		margin: 0px;
		padding: 0px;
		border: none;

		&:disabled {
			* {
				cursor: default;
			}

			// .form-label {
			// 	@include flex(row, flex-start, flex-start);
			// }

			.form-value:not(div) {
				border: none;
				padding: 0.5rem 0px;
				outline: none;
				resize: none;
				background: transparent;
				color: $black;
				opacity: 1;
				appearance: none;
				font-family: inherit;
				font-size: inherit;
			}

			label[data-mandatory='true']::after {
				content: '';
			}
		}
	}

	.info-field,
	.form-field {
		margin-bottom: 1.25rem;
		.info-label,
		.form-label {
			width: 100%;
			max-width: 150px;
			min-width: 120px;
			padding-right: 1rem;
			color: rgba($dark-grey, 0.75);
			@include rwd(1080) {
				max-width: 180px;
				min-width: 150px;
			}
		}

		.info-value,
		.form-value {
			width: 250px;
			line-height: 1.5rem;
			&.sm {
				width: 80px;
			}

			&[type='number'] {
				@include removeNumInputArrows;
			}
		}

		.form-label {
			@include flex(row, flex-start, flex-start);
			height: 34px;
		}

		.form-value:not(div) {
			border-radius: 4px;
			border: 1px solid $mid-grey;
			padding: 0.5rem 0.75rem;
			outline: none;
			resize: none;
			background: $white;
			font-family: inherit;
			font-size: inherit;
		}
	}

	.form-field {
		// position: relative;
		.form-error {
			position: absolute;
			right: 0px;
			bottom: -1rem;
			font-size: 0.75rem;
			width: 100%;
			text-align: right;
			color: red;
		}
		.form-info {
			color: rgba($dark-grey, 0.75);
		}
	}

	label[data-mandatory='true']::after {
		content: '*';
	}

	.dot-container {
		@include flex;
		width: 30px;
		height: 30px;
		border: 1px solid $mid-grey;
		border-radius: 4px;
		position: relative;
		cursor: pointer;

		&.small {
			cursor: default;
			width: 20px;
			height: 20px;

			.dot {
				width: 10px;
				height: 10px;
			}
		}

		.dot {
			width: 15px;
			height: 15px;
			border-radius: 50px;
		}

		.tick {
			display: none;
			width: 15px;
			height: 15px;
			position: absolute;
			bottom: -20px;
		}

		&.selected {
			border-color: black;

			.tick {
				display: block;
			}
		}

		&.veg {
			.dot {
				background: $success;
			}
		}

		&.non-veg {
			.dot {
				background: $pastel-red;
			}
		}

		&.disabled {
			&:not(.selected) {
				display: none;
			}
			.tick {
				display: none;
			}
		}
	}

	.display-pic-root {
		.display-pic-preview {
			@include flex;
			position: relative;
			width: 200px;
			max-width: 100%;
			height: 150px;
			background: $pastel-red;
			border-radius: 4px;

			.display-pic {
				width: 100%;
				height: 100%;
				border-radius: 4px;
				&.show {
					visibility: visible;
				}
				&.hide {
					visibility: hidden;
				}
			}

			.display-pic-edit {
				@include flex;
				visibility: visible;
				opacity: 0.5;
				cursor: pointer;
				width: 2rem;
				height: 2rem;
				background: $pastel-red;
				color: $white;
				position: absolute;
				right: 0.5rem;
				top: 0.5rem;
				border: none;
				border-radius: 4px;
				svg {
					font-size: 1.25rem;
				}
			}

			&:hover {
				.display-pic-edit {
					opacity: 1;
				}
			}
		}
		.display-pic-label {
			@include flex;
			cursor: pointer;
			width: 200px;
			max-width: 100%;
			background: $primary;
			color: $white;
			padding: 0.75rem;
			margin: 1rem 0px;
			border-radius: 4px;
			text-transform: uppercase;
		}
		&.ratio-3-4 {
			.display-pic-preview {
				width: 150px;
				height: 200px;
			}
			.display-pic-label {
				width: 150px;
			}
		}
	}
}

.variant-circle {
	@include flex;
	width: auto;
	height: 25px;
	background: rgba($dark-grey, 0.4);
	border-radius: 4px;
	padding: 0.25rem;
	font-size: 0.875em;
}

.sub-category {
	@include flex(row, flex-start, flex-start);
	width: auto;
}

.create-alert-root {
	text-align: center;
	border: 1px solid rgb(64, 64, 64);
	max-width: 420px;
	height: 125px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background: white;
	border-radius: 4px;

	.heading {
		font-size: 1.5rem;
	}

	.message {
		font-size: 1rem;
	}

	.create-alert-btn {
		border-radius: 4px;
		border: none;
		padding: 0.5rem 1rem;
		background: $primary;
		color: white;
		cursor: pointer;
	}
}

@layer utilities {
	.scrollbar-thin::-webkit-scrollbar {
	  width: 10px;
	  height: 10px;
	}
  
	.scrollbar-thin::-webkit-scrollbar-track {
	  border-radius: 100vh;
	  background: #fff3d4;
	}
  
	.scrollbar-thin::-webkit-scrollbar-thumb {
	  background: #FFB703;
	  border-radius: 100vh;
	  border: 3px solid #ffffff;
	}
  
	.scrollbar-thin::-webkit-scrollbar-thumb:hover {
	  background: #ffaa00;
	}

	.scrollbar-thick::-webkit-scrollbar {
	  width: 18px;
	  height: 18px;
	}
  
	.scrollbar-thick::-webkit-scrollbar-track {
	  border-radius: 100vh;
	  background: #fff3d4;
	}
  
	.scrollbar-thick::-webkit-scrollbar-thumb {
	  background: #FFB703;
	  border-radius: 100vh;
	  border: 5px solid #ffffff;
	}
  
	.scrollbar-thick::-webkit-scrollbar-thumb:hover {
	  background: #ffaa00;
	}

	
  }