@import '../../../Styles/';

.root {
    height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;

    .header{
        width: 100%;
        padding: 0.5rem 1rem;
        @include flex(row, space-between);
    }

    .table-container {
        // max-height: 55vh;
        overflow-y: auto;
        flex: 1;
        overflow-x: auto;
        // margin-top: 1rem;
        
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
        }
    }

    .listItem{
        height: 3rem !important;
        min-width: fit-content;
        &.active{
            background-color: #fdf8de !important;
        }
        &.complete{
            background-color:rgba(0, 128, 0, 0.425) !important;
        }
        &.cancel{
            background-color: rgba(255, 17, 0, 0.5) !important;
        }
        &.done{
            background-color: #FFB703 !important;
        }
        .action-btns{
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
            white-space: nowrap;
        }
    }
}

.edit-container{
    min-width: fit-content;
    @include flex(row, flex-start);
    gap: 0.5rem;
    white-space: nowrap;
}