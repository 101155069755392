@import '../../Styles/index';
.root {

    .version{
        width: 100%;
        @include flex;
        gap: 1rem; 
        span{
            border-radius: .5rem;
            font-weight: 600;
            // background-color: rgba(223, 223, 223, 0.437);
            background: $uy-ex-light-bg;
            padding: .1rem 1rem;
        }
    }

    .header {
        width: 100%;
        // height: 8rem;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;

        .heading {
            width: 40%;
            height: 10rem;
            display: flex;
            font-size: xx-large;
            font-weight: 600;
            align-items: center;
            justify-content: center;
        }

        .filter {
            width: 60%;
            height: 10rem;

        }
    }
    .animate {
        animation: zoom-in-zoom-out 0.3s ease;
        animation-delay: 1s;
        animation-iteration-count: 2;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.2, 1.2);
            border: 2px solid rgb(57, 73, 0);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    .btn {
        svg {
            font-size: 2.5rem;
            color: $uy-bg;
        }


    }

    .disabled {
        svg {
            color: rgba($primary, 0.4) !important;
        }
    }

}